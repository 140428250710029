<template>
  <div>
    <!-- Your template content here -->
    <el-dialog
      @close="close"
      :close-on-click-modal="false"
      v-model="dialogVisible"
      :title="title || '修改密码'"
      width="400"
      draggable
    >
      <el-form :rules="rules" ref="formRef" :model="form" label-width="120px">
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="form.newPassword"
            placeholder="请输入新密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            v-model="form.confirmPassword"
            placeholder="请再次输入新密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="ok"> 确认 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { updatePasswordApi } from "@/api/login";
import { removeToken } from "@/utils/auth";
import { ElMessage, ElMessageBox } from "element-plus";

const dialogVisible = ref(true);
// 定义参数
const props = defineProps({
  title: String,
  // 如果需要的话，可以添加其他参数的定义
});
const formRef = ref();
const form = ref({
  newPassword: "",
  confirmPassword: "",
});
const validatePassword = (rule, value, callback) => {
  const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  if (value === "") {
    callback(new Error("请输入密码"));
  } else if (!passwordReg.test(value)) {
    callback(new Error("密码必须是包含大小写的最少8位的数字"));
  } else {
    callback();
  }
};
const isAlike = (rule, value, callback) => {
  if (value !== form.value.newPassword) {
    callback(new Error("两次输入密码不一致"));
  } else {
    callback();
  }
};

const rules = {
  newPassword: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { validator: validatePassword, trigger: "change" },
  ],
  confirmPassword: [
    { required: true, message: "请再次输入密码", trigger: "blur" },
    { validator: validatePassword, trigger: "change" },
    { validator: isAlike, trigger: "blur" },
  ],
};
// 定义要发射的事件
const emits = defineEmits(["close", "ok"]);
const ok = async () => {
  await formRef.value.validate(async (valid, fields) => {
    if (valid) {
      ElMessageBox.confirm("确认修改密码吗?", "修改密码", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            credential: form.value.confirmPassword,
          };
          await updatePasswordApi(params);
          removeToken();
          ElMessage.success("修改成功");
          location.reload();
          emits("ok", form.value);
          close()
        })
        .catch(() => {
          //
        });
    } else {
      //   emits("error", form.value);
    }
  });
  // 直接调用接口 修改密码后 自行处理剩余逻辑
};
const close = () => {
  emits("close");
};
</script>

<style scoped lang="less"></style>

import request from "@/utils/request";

// 登录
export function doLoginApi(data) {
  return request({
    url: "/athlete/doLogin",
    method: "post",
    data,
  });
}
export function adminApi(data) {
  return request({
    url: "/admin/doLogin",
    method: "post",
    data,
  });
}

// 注册
export function doRegisterApi(data) {
  return request({
    url: "/athlete/doRegister",
    method: "post",
    data,
  });
}
// 发送验证码
export function sendCodeApi(data) {
  return request({
    url: "/athlete/sendCode",
    method: "post",
    data,
  });
}
// 获取个人信息
export function getUserInfoApi(params) {
  return request({
    url: "/athlete/info",
    method: "get",
    params,
  });
}
// 更新个人信息
export function updateUserInfoApi(data) {
  return request({
    url: "/athlete/update",
    method: "post",
    data,
  });
}
// 忘记密码
export function forgotPasswordApi(data) {
  return request({
    url: "/athlete/forgotPassword",
    method: "post",
    data,
  });
}

// 注册验证

export function verifyApi(params) {
  return request({
    url: "/user/verify",
    method: "post",
    params,
  });
}

// 退出登录
export function logoutApi(params) {
  return request({
    url: "/user/logout",
    method: "get",
    params,
  });
}

// 更新密码
export function updatePasswordApi(data) {
  return request({
    url: "/user/updatePassword",
    method: "post",
    data,
  });
}

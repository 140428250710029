<template>
  <div>
    <!-- Your template content here -->
    <div class="footer">
      <div class="top">
        <div class="info">
          <div class="info__container">
            <div class="title">FOLLOW THE ACTION</div>
            <div class="icon">
              <a href="https://b23.tv/t5Dje7N" target="_blank">
                <img
                 style="height: 30px"
                  src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/17215106634652364_BILIBILI_LOGO.png"
                  alt=""
                />
              </a>
              <a href="https://v.douyin.com/iMNtA8Lf" target="_blank">
                <img style="width: 30px;height: 30px"
                  src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/1721510514351d34a_抖音0.png"
                  alt=""
                />
              </a>
              <a
                href="https://www.xiaohongshu.com/user/profile/65793490000000002003233c?xhsshare=CopyLink&appuid=65793490000000002003233c&apptime=1721202163&share_id=736628f1cb074801bd480512613c3425"
                target="_blank"
              >
                <img
                  style="width: 74px;height: 74px"
                  src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/1721510633916290f_小红书.png"
                  alt=""
                />
              </a>
              <a href="https://weibo.com/u/5094069607" target="_blank">
                <img style="width: 36px;height: 36px"
                  src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/1721510579869a9e3_微博0.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="centre">
        <div class="logo">
          <a href="https://hurcn.com/" target="_blank">
            <img
              src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/17215111364638d74_WechatIMG671.png"
              alt=""
            />
          </a>
          <a href="https://www.fise.fr/fr" target="_blank">
            <img
              src="https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/17215108127333cb6_微信图片_20240716120520.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="bottom">
        <div class="beian">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >沪ICP备2024079288号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import "./index.less";
import { ref, computed } from "vue";

// 定义参数
const props = defineProps({
  problemList: Array,
  fileList: Array,
  // 如果需要的话，可以添加其他参数的定义
});

// 定义要发射的事件
const emits = defineEmits(["deleteFn", "addFn"]);

const count = ref(0);

const doubledCount = computed(() => count.value * 2);

function increment() {
  count.value++;
}
</script>

<style scoped lang="less">
@font-face {
  font-family: "Bold";
  src: url("../../assets/proxima-nova-bold.otf");
  font-weight: normal;
  font-style: normal;
}
.top,
.centre,
.bottom {
  background: #000000;
  > div {
    margin: 0 auto;
  }
}
.top {
  position: relative;
  background: url("https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/17215098578741cbc_关注我们 底图.jpeg")
    no-repeat 50%;
  background-size: cover;
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
  position: relative;
  .info {
    width: 50%;
    margin: 0 auto;
    z-index: 10;
    position: relative;
    .title {
      color: #fff;
      font-weight: 900;
      font-size: 2.375rem;
      margin-bottom: 1rem;
      font-family: 'Bold';
    }
    .icon {
      display: flex;
      align-items: center;
      img {
        // width: 20px;
        height: 20px;
        margin: 0 0.5rem;
      }
      a {
        transition: 0.5s;
        text-emphasis: none;
      }
      a:hover {
        transform: scale(0.8);
      }
    }
  }
}
.top:before {
  background: rgb(0 0 0 / 50%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
}
.centre {
  a {
    transition: 0.5s;
    text-emphasis: none;
  }
  a:hover {
    transform: scale(0.9);
  }
  border-top: 0.5px solid hsla(0, 0%, 92%, 0.15);
  border-bottom: 0.5px solid hsla(0, 0%, 92%, 0.15);
  .logo {
    padding: 2rem 0;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    img {
      width: auto;
      height: 68px;
      margin: 0 20px;
    }
    a:nth-child(2) img {
      height: 40px;
    }
  }
}
.bottom {
  text-align: center;
  font-size: 0.675rem;
  padding: 0.6rem;
  a {
    color: #cccccc;
  }
  a:hover {
    color: #eeeeee;
  }
}
@media (max-width: 768px) {
  .top,
  .centre,
  .bottom {
    > div {
      width: 70vw !important;
    }
  }
}

a:before {
  display: none !important;
}
</style>

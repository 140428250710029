import request from "@/utils/request";

// 获取首页banner
export function getHome(params: any) {
  return request({
    url: "/c-page/home-banner",
    method: "get",
    params,
  });
}
export function getNews(params: any) {
    return request({
      url: "/c-page/news-banner",
      method: "get",
      params,
    });
  }
  export function getMatch(params: any) {
    return request({
      url: "/c-page/match-banner",
      method: "get",
      params,
    });
  }
  export function getLink(params: any) {
    return request({
      url: "/c-page/ticketing-link",
      method: "get",
      params,
    });
  }

  


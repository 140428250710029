import axios from "axios";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import { getToken, removeToken, removeInfo } from "@/utils/auth";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";

const isLoading = ref(true);
const time = ref();
var loadingInstance: any;
// 创建请求
let baseUrl = "";

// if(process.env.VUE_ENV == "development")
if (process.env.NODE_ENV == "development") {
  baseUrl = `${process.env.VUE_APP_BASE_API}`;
} else {
  baseUrl = window.location.origin  + process.env.VUE_APP_BASE_API;
}
const service = axios.create({
  baseURL: baseUrl,
  // baseURL: `${process.env.VUE_APP_BASE_API}/api/v1`,
  // vue_APP_BASE_HOST
  // baseURL: process.env.VUE_APP_BASE_HOST, // url = base url + request url 打包环境

  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
});

// 请求拦截
service.interceptors.request.use(
  (config) => {
    let configData;
   
    // if (config.url != "/payQuery" && isLoading.value && (configData && configData.domain)) {
    //   loadingInstance = ElLoading.service({
    //     text: "加载中",
    //   });
    // }
    if (time.value) {
      clearTimeout(time.value);
    }
    // time.value = setTimeout(() => {
    //   isLoading.value = true;
    // }, 1000);
    config.headers["fise-token"] = getToken();

    return config;
  },
  (error) => {
    console.log(error, "error");
    const { code, message } = error;
    if (error.message.includes("timeout")) {
      ElMessage({
        message: "请求超时,真超时",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
    // if (code != 2000) {
    //   ElMessage({
    //     message: error.message,
    //     type: "error",
    //     duration: 5 * 1000,
    //   });
    // }

    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    removeloading();
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.

    if (res.code !== 2000) {
      // 全局报错提示框
      ElMessage({
        message: res.message || "Error",
        type: "error",
        duration: 3 * 1000,
      });
      console.log(res.code, "res.code");

      if (res.code == 10102) {
        const router = useRouter();
        const route = useRoute();
        removeToken();
        removeInfo();
        window.location.href = "/login";
      }
      return Promise.reject(res.message || "Error");
    } else {
      return res;
    }
  },
  (error) => {
    removeloading();
    if (error.message.includes("timeout")) {
      ElMessage({
        message: "请求超时,请重试",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
    ElMessage({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

function removeloading() {
  isLoading.value = false;
  if (loadingInstance) {
    setTimeout(() => {
      loadingInstance.close();
    }, 800);
  }
}

export default service;

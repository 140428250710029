import Cookies from "js-cookie";
import bus from "@/utils/bus";
const TokenKey = "access_token";
// 个人信息
const info = "info";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token: any) {
  return Cookies.set(TokenKey, token || "");
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
// 个人信息
export function getInfo() {
  const data = Cookies.get(info);
  const value = data ? JSON.parse(data) : null;
  return value;
}

export function setInfo(data: any) {
  bus.emit("updateUserInfo", data);
  return Cookies.set(info, data ? JSON.stringify(data) : "");
}

export function removeInfo() {
  return Cookies.remove(info);
}

<template>
  <div class="page">
    <!-- Your template content here -->
    <div class="header">
      <div class="left">
        <div class="user-menu">
          <el-menu
            style="width: 100%"
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            popper-effect="dark"
            :background-color="'#1A1A1A'"
            :text-color="'#fff'"
            :ellipsis="false"
            menu-trigger="click"
            @select="handleSelect"
            popper-class="menu-popper"
          >
            <el-sub-menu index="1">
              <template #title
                ><el-icon style="font-size: 2.5rem"><Menu /></el-icon
              ></template>
              <el-menu-item index="home" @click="goRouter('/home')"
                >首页</el-menu-item
              >
              <el-sub-menu index="2">
                <template #title>关于我们</template>
                <el-menu-item class="font" index="2-1" @click="goRouter('/brand?type=fise')"
                  >关于FISE</el-menu-item
                >
                <el-menu-item
                  index="2-2"
                  @click="goRouter('/group?type=hurricane')"
                  >关于飓风集团</el-menu-item
                >
              </el-sub-menu>
              <el-menu-item index="journalism" @click="goRouter('/journalism')"
                >新闻</el-menu-item
              >
              <el-sub-menu
                v-if="matchs && matchs.length && false"
                index="competition"
              >
                <template #title>赛事</template>
                <el-menu-item
                  v-for="(item, index) in matchs"
                  :key="index"
                  :index="`2-${index}`"
                  @click="goRouter(`/competitionInfo?id=${item.id}`)"
                  >{{ item.title }}</el-menu-item
                >
              </el-sub-menu>
              <el-menu-item
                v-else
                index="competition"
                @click="goRouter('/competition')"
                >赛事</el-menu-item
              >
              <el-menu-item index="4" @click="goRouter('/ticketing?type=ticketing')"
                >购买门票</el-menu-item
              >
            </el-sub-menu>
          </el-menu>
        </div>
        <el-image :src="LogoW" class="image"></el-image>
      </div>
      <div class="menu">
        <el-image :src="LogoW" class="image"></el-image>
        <!-- 菜单  :default-active="activeIndex" -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          popper-effect="dark"
          :background-color="'#1A1A1A'"
          :text-color="'#fff'"
          :ellipsis="false"
          menu-trigger="hover"
          @select="handleSelect"
        >
          <el-menu-item index="home" @click="goRouter('/home')"
            >首页</el-menu-item
          >
          <el-sub-menu index="2" popperClass="header-popper">
            <template #title>关于我们</template>
            <template #default>
              <Brand />
            </template>
          </el-sub-menu>
          <el-menu-item index="journalism" @click="goRouter('/journalism')"
            >新闻</el-menu-item
          >
          <el-sub-menu
            v-if="matchs && matchs.length"
            index="competition"
            popperClass="header-popper"
          >
            <template #title>赛事</template>
            <template #default>
              <Competition
                type="recent"
                title="近期赛事"
                :slice="2"
                all
              ></Competition
            ></template>
          </el-sub-menu>
          <el-menu-item
            v-else
            index="competition"
            @click="goRouter('/competition')"
            >赛事</el-menu-item
          >
          <el-menu-item index="4" @click="goRouter('/ticketing?type=ticketing')">购买门票</el-menu-item>
        </el-menu>
      </div>
      <div class="right">
        <div class="user">
          <template v-if="!userInfo">
            <div class="btn" @click="goUser">运动员注册</div>
          </template>
          <template v-else>
            <el-dropdown :hide-on-click="false">
              <div class="flex">
                <img
                  class="avatar"
                  v-if="userInfo.avatar"
                  :src="userInfo.avatar"
                  alt=""
                />
                <el-icon class="user-icon" v-else><User /></el-icon>
                <div class="name" v-if="userInfo.name">{{ userInfo.name }}</div>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="goRouter('/user')"
                    >个人信息</el-dropdown-item
                  >
                  <el-dropdown-item
                    divided
                    @click="componentName = 'updatePassword'"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item divided @click="logOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </div>
      </div>
    </div>
    <div class="xd-none"></div>

    <component
      :is="components[componentName]"
      @ok="okFn"
      @close="close"
    ></component>
    <el-drawer
      v-model="dialogVisible"
      :title="title"
      direction="ltr"
      :before-close="closed"
    >
    </el-drawer>
  </div>
</template>

<script setup>
import Logo from "@/assets/logo.png";
import LogoB from "@/assets/logo-b.png";
import LogoW from "@/assets/logo-w.png";
import { ref, computed, onMounted } from "vue";
import { getInfo, removeToken, removeInfo } from "@/utils/auth";
import { User, Menu } from "@element-plus/icons-vue";
import { logoutApi } from "@/api/login";
import { useRouter } from "vue-router";
import { matchList } from "@/api/competition";
import Competition from "@/components/competition/index.vue";
import Brand from "./components/brand.vue";
import register from "@/components/register.vue";
import updatePassword from "@/components/updatePassword";
import drawerCom from "./components/drawer.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import bus from "@/utils/bus";
import { getLink } from "@/api/home";

// 响应式处理
const userInfo = ref(getInfo());
const router = useRouter();

// 购票链接跳转
const link = ref();

const dialogVisible = ref(false);
// 更新个人信息
bus.on("updateUserInfo", (value) => {
  console.log("个人信息:", value);
  userInfo.value = value;
});
bus.on("activeIndex", (value) => {
  activeIndex.value = value;
});
const componentName = ref();
const components = {
  updatePassword,
  drawerCom,
};
const matchs = ref([]);
// 定义参数
const props = defineProps({
  problemList: Array,
  fileList: Array,
  // 如果需要的话，可以添加其他参数的定义
});

const activeIndex = ref("1");
const handleSelect = (e) => {
  activeIndex.value = e;
};
const goUser = () => {
  router.push(`/user`);
};
const opend = () => {
  dialogVisible.value = !dialogVisible.value;
  // componentName.value = "drawerCom";
};
const closed = () => {
  dialogVisible.value = false;
  // componentName.value = "drawerCom";
};

const goRouter = (url) => {
  router.push(url);
  closed();
};
const goExternal = () => {
  getTicketing();
  if (!link.value) {
    ElMessage.error("未配置跳转链接");
    return;
  }
  window.open(link.value);
  closed();
};
const goUrl = (url) => {
  window.open(url);
  closed();
};
const close = () => {
  componentName.value = null;
};
const okFn = async (data) => {
  console.log(data, "修改密码成功");
};
const logOut = async () => {
  // 二次确认
  ElMessageBox.confirm("确认退出登录吗?", "退出登录", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      await logoutApi();
      removeToken();
      removeInfo();
      ElMessage.success("退出成功");
      location.reload();
    })
    .catch(() => {
      //
    });
};
const getTicketing = async () => {
  const res = await getLink();
  link.value = res.data;
};
const xd = ref(false);
onMounted(async () => {
  await getTicketing();
  window.addEventListener("scroll", function () {
    if (window.pageYOffset > 80) {
      xd.value = true;
    } else {
      xd.value = false;
    }
  });
  const res = await matchList({ type: "ongoing" });
  matchs.value = res.data.records.slice(0, 2);
});
</script>

<style scoped lang="less">

.page {
  display: flex;
  justify-content: center;
  background: #1a1a1a;
}
/* Your scoped CSS here */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 4vw);
  background: #1a1a1a;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0 2vw;
  z-index: 2000;
  .image {
    width: 20vw;
    max-width: 100px;
    height: auto;
  }

  .right {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
  }
}
.user {
  display: flex;
  align-items: center;
  color: #1a1a1a;
  .flex {
    display: flex;
    align-items: center;
    cursor: pointer;
    .user-icon {
      font-size: 1.25rem;
      color: #fff;
    }
    .avatar {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      min-height: 2.5rem;
      border-radius: 50%;
      color: #fff;
    }
    .name {
      max-width: 100px;
      white-space: nowrap;
      /* 隐藏超出容器的文字 */
      overflow: hidden;
      /* 显示省略号 */
      text-overflow: ellipsis;
      margin-left: 0.5rem;
      color: #fff;
    }
  }
}
.xd-none {
  width: 100%;
  height: 70px;
  background: #1a1a1a;
}

.xd {
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  transition: 1s;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 999;
  background: transparent;
  .xd-box {
    width: 100%;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 28px;
      transition: 1s;
      cursor: pointer;
    }
    .right {
    }
  }
  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.top0 {
  top: 0;
  background: #fff;
}
ul {
  border-bottom: none !important;
  border-right: none !important;
  // li {
  //   color: #fdd600 !important;
  // }
  // li:hover,
  // li:focus {
  //   background: #1a1a1a;
  // }
}
:deep(.el-dropdown):focus-visible {
  outline: none;
}
.btn {
  background-color: #fdd600;
  color: #131313;
  font-weight: bold;
  padding: 0.6375rem 2.1875rem;
  border: none;
  border-radius: 0.1875rem;
  font-size: 0.8125rem;
  cursor: pointer;
  transition: 0.5s;
}
.btn:hover {
  background-color: #131313;
  color: #fff;
}

@media (max-width: 768px) {
  .menu {
    .el-menu-demo {
      display: none;
    }
  }
  .user-menu {
    display: block;
  }
  .left {
    .image {
      display: none;
    }
  }
  .header {
  }
}
@media (min-width: 768px) {
  .menu {
    display: block;
    .image {
      display: none;
    }
  }
  .user-menu {
    width: 100px;
    display: none;
  }
}
</style>

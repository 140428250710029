import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "../layouts/index.vue";
import { getToken, removeInfo } from "@/utils/auth";
import { ref, computed } from "vue";
import bus from "@/utils/bus";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/", // 重定向路由
    redirect: "/home",
    // component: () => import('@/views/layout/components/Sidebar/redirect'), hidden: true
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: { title: "首页" },
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: { title: "登录" },
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/register/index.vue"),
        meta: { title: "注册" },
      },
      {
        path: "journalism",
        name: "journalism",
        component: () => import("@/views/journalism/index.vue"),
        meta: { title: "新闻列表" },
      },
      {
        path: "journalismInfo",
        name: "journalismInfo",
        component: () => import("@/views/journalism/info/index.vue"),
        meta: { title: "新闻详情" },
      },
      {
        path: "competition",
        name: "competition",
        component: () => import("@/views/competition/index.vue"),
        meta: { title: "赛事列表" },
      },
      {
        path: "competitionInfo",
        name: "competitionInfo",
        component: () => import("@/views/competition/info/index.vue"),
        meta: { title: "赛事详情" },
      },
      // 个人中心
      {
        path: "user",
        name: "user",
        component: () => import("@/views/user/index.vue"),
        meta: { title: "个人中心" },
      },
      {
        path: "apply",
        name: "apply",
        component: () => import("@/views/apply/index.vue"),
        meta: { title: "赛事报名" },
      },
      {
        path: "brand",
        name: "brand",
        component: () => import("@/views/brand/index.vue"),
        meta: { title: "关于FISE" },
      },
      {
        path: "group",
        name: "group",
        component: () => import("@/views/brand/group.vue"),
        meta: { title: "飓风集团" },
      },
      {
        path: "ticketing",
        name: "ticketing",
        component: () => import("@/views/brand/ticketing.vue"),
        meta: { title: "购买门票" },
      },
      {
        path: "debug",
        name: "debug",
        component: () => import("@/views/debug/index.vue"),
        meta: { title: "debug" },
      },
      
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 定义白名单数组，这些是已登录用户不应访问的页面
const whiteList = ["/login", "/register"];
const blacklist = ["/user", "/apply"]; // 定义黑名单
router.beforeEach((to, from, next) => {
  bus.emit("loader", true);
  const token = getToken();
  // 未登录的情况下 不可以进入 都会跳转到登录页面
  if (blacklist.includes(to.path)) {
    if (!token) {
      next(`/login?redirect=${encodeURI(to.fullPath)}`);
      return;
    }
  }
  // 已登录用户 无法进入 白名单页面
  if (whiteList.includes(to.path) && token) {
    next("/user");
    return;
  }
  if (!token) {
    removeInfo();
  }
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, 500);
  next();
});
export default router;

import request from "@/utils/request";

export const brandingApi = (type) => {
  return request({
    url: "/c-page/articles/branding/"+type,
    method: "get",
  });
};


// 运动员信息

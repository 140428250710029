<template>
  <div>
    <div class="brand animate__animated animate__fadeIn">
      <div
        class="item "
        v-for="(item, index) in list"
        :key="index"
        @click="goRouter(index)"
      >
        <div class="left ">
          <!-- :style="`background: url('${item.banner}');
        background-size: 100% 100%;`" -->
          <img
            :src="item.banner"
            alt=""
            :style="{
              width: index ? 'auto' : 'auto',
              height: index ? '70px' : '100px',
            }"
          />
        </div>
        <div class="right">
          <div class="title font"><el-icon><CaretRight /></el-icon>{{ item.title }}</div>
          <div class="brief">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CaretRight} from "@element-plus/icons-vue";
import { ref, computed, watch, reactive, onMounted } from "vue";
import { brandingApi } from "@/api/brand";
import { useRouter } from "vue-router";
import { h } from "vue";
const router = useRouter();

const list = ref([
  {
    title: "HURRICANE",
    banner: "https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/17217881333161a3f_Hurricane-group-logo-horizontal.png",
    desc: "飓风集团作为FISE母公司，专注于城市体育，在赛事组织、创意设计、极限运动公园、人才培养和媒体传播5个关键领域拥有360°的专业知识。",
  },
  {
    title: "FISE",
    banner: "https://fise-1323765047.cos.ap-shanghai.myqcloud.com/fise/172175240220251a9_FISE.png",
    desc: "FISE自1997年在蒙彼利埃成立以来，通过建设极限运动场地，举办各类级别的国际赛事，长期致力于推广极限运动文化，为全球极限运动爱好者提供一个展示才华的平台。",
  },
]);
const goRouter = (index) => {
  router.push(index ? "/brand?type=fise" : "/group?type=hurricane");
};
const init = async () => {};
onMounted(() => {
  init();
});
</script>

<style scoped lang="less">
.brand {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  .item {
    width: 48%;
    display: flex;
    cursor: pointer;
    .left {
      transition: 1s;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: calc(60% - 1rem);
      padding-left: 1rem;
      .title {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 1;
      }
      .brief {
        margin-top: 1rem;
      }
    }
  }
}
.item:hover .left{
  transform: scale(0.9);
}
</style>

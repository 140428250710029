<template>
  <div>
    <!-- Your template content here -->

    <el-drawer
      v-model="dialogVisible"
      :title="title"
      direction="ltr"
      :before-close="close"
    >
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        popper-effect="dark"
        popper-class="menu-popper"
        :text-color="'#1A1A1A'"
        :ellipsis="false"
        @select="handleSelect"
      >
        <el-menu-item index="home" @click="goRouter('/home')"
          >首页</el-menu-item
        >
        <el-sub-menu index="2">
          <template #title>关于我们</template>
          <el-menu-item index="2-1" @click="goUrl('https://www.fise.fr/')"
            >关于FISE</el-menu-item
          >
          <el-menu-item index="2-2" @click="goUrl('https://www.fise.fr/')"
            >关于飓风集团</el-menu-item
          >
        </el-sub-menu>
        <el-menu-item index="journalism" @click="goRouter('/journalism')"
          >新闻</el-menu-item
        >
        <el-menu-item index="competition" @click="goRouter('/competition')"
          >赛事</el-menu-item
        >
        <el-menu-item index="4" @click="goExternal">购买门票</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import bus from "@/utils/bus";
import { useRouter } from "vue-router";
const activeIndex = ref("1");

const router = useRouter();
bus.on("activeIndex", (value) => {
  activeIndex.value = value;
});
const dialogVisible = ref(true);
// 定义参数
const props = defineProps({
  title: String,
  // 如果需要的话，可以添加其他参数的定义
});
const formRef = ref();
const goUrl = (url) => {
  window.open(url);
};
const emits = defineEmits(["close", "ok"]);
const close = () => {
  emits("close");
};
const goRouter = (url) => {
  router.push(url);
};
</script>

<style scoped lang="less">
.el-sub-menu__title {
  font-weight: bold;
}

ul {
  border-right: none !important;
  // li {
  //   color: #fdd600 !important;
  // }
  // li:hover,
  // li:focus {
  //   background: #1a1a1a;
  // }
}
</style>

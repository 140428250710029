<template>
  <div class="competition">
    <!-- Your template content here -->
    <template v-if="list && list.length">
      <h2>
        {{ title }}
        <el-button v-if="all" link @click="goAll" class="color"
          >查看全部赛事</el-button
        >
      </h2>
      <div class="bottom animate__animated animate__fadeIn">
        <div
          class="item animate-scale"
          v-for="(item, index) in slice ? list.slice(0, slice) : list"
          :key="index"
          @click="goInfo(item.id)"
        >
          <!-- <div class="left">
            <img :src="item.banner" alt="" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="time">
              {{ item.matchStartTime }} - {{ item.matchEndTime }}
            </div>
            <div class="metas">滑雪、滑冰、自行车</div>
          </div> -->
          <div class="por">
            <img class="image" :src="item.banner" alt="" />
            <div class="poa">
              <div class="poa-main">
                <div class="title">{{ item.title }}</div>
                <!-- <div class="metas">
                  {{ item.matchEventsList.map((ele) => ele.name).join("，") }}
                </div> -->
                <div class="time">
                  {{ item.matchStartTime }} - {{ item.matchEndTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more">
        <el-button
          :disabled="loading"
          loading
          @click="moreFn"
          v-if="total > list.length"
          >加载更多</el-button
        >
        <span v-else-if="total > 15">暂无更多内容</span>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, reactive, onMounted } from "vue";
import { getList } from "@/api/competition";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  type: Array,
  title: String,
  slice: Number,
  all: Boolean,
  // 如果需要的话，可以添加其他参数的定义
});

const list = ref([]);
const total = ref(0);
const loading = ref(false);
const state = reactive({
  page: 1,
  limit: 10,
  type: props.type,
});
// 定义参数

const goList = () => {
  router.push(`/competition`);
};
const goInfo = (id) => {
  router.push(`/competitionInfo?id=${id}`);
};
const goAll = () => {
  router.push(`/competition`);
};

const init = () => {
  state.page = 1;
  getListApi();
};
const getListApi = async () => {
  try {
    loading.value = true;
    const res = await getList(state);
    const { records, total } = res.data;
    list.value = records;
    total.value = total;
    loading.value = false;
  } catch (error) {
    loading.value = false;
  }
};
const moreFn = () => {
  state.page += 1;
  getData();
};
onMounted(() => {
  init();
});
// 定义要发射的事件
const emits = defineEmits([]);
</script>

<style scoped lang="less">
.competition {
  width: 100%;
  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // .item {
    //   width: calc(50% - 1.875rem);
    //   cursor: pointer;
    //   margin-bottom: 1.875rem;
    //   border: 1px solid #ebebeb;
    //   display: flex;
    //   border-radius: 8px;
    //   overflow: hidden;
    //   .left {
    //     height: 100%;
    //     width: 42%;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    //   .right {
    //     width: 58%;
    //     padding: 2rem;
    //     .title {
    //       font-size: 1.5rem;
    //       font-weight: 600;
    //       line-height: 1.25;
    //       margin-bottom: 0.5rem;
    //     }
    //     .time {
    //       font-size: 1.25rem;
    //       font-weight: 700;
    //       text-transform: uppercase;
    //     }
    //     .metas {
    //       border-top: 1px solid #ebebeb;
    //       margin-top: 0.9375rem;
    //       padding-top: 0.9375rem;
    //       width: 100%;
    //       font-size: 1rem;
    //     }
    //   }
    // }
    .item {
      width: calc(50% - 1.875rem);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      .por {
        position: relative;
        width: 100%;
        height: 100%;
        .image {
          width: 100%;
          height: 100%;
          vertical-align: bottom;
        }
        .poa {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          color: #fff;
          z-index: 1;
          // background-color: #131313;
          // opacity: 0.5;
          .poa-main {
            width: calc(100% - 4rem);
            position: absolute;
            bottom: 1rem;
            left: 0;
            padding: 2rem;
            > div {
              text-align: center;
              overflow: hidden; /* 超出的内容隐藏 */
              white-space: nowrap; /* 文本不换行 */
              text-overflow: ellipsis; /* 超出的文本显示省略号 */
            }
            .title {
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1.25;
              margin-bottom: 0.5rem;
            }
            .metas {
              font-size: 0.9375rem;
              font-weight: 600;
              margin-bottom: 0.3125rem;
            }
            .time {
              border-top: 0.0625rem solid hsla(0, 0%, 100%, 0.25);
              margin-top: 0.9375rem;
              padding-top: 0.9375rem;
              font-size: 1.25rem;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
        }
      }
      .por::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #131313;
        opacity: 0.3;
      }
    }
  }
}
.more {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  color: #999;
}
@media (max-width: 768px) {
  .item {
    width: 100% !important;
  }
}
/* Your scoped CSS here */
</style>

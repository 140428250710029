import request from "@/utils/request";

// 获取首页banner
export function getInfo(id: any) {
  return request({
    url: "/c-page/match/id/" + id,
    method: "get",
  });
}
export function getInfoApi(id: any) {
  return request({
    url: "/c-page/match/id/" + id,
    method: "get",
  });
}
export function getList(params: any) {
  return request({
    url: "/c-page/match/list",
    method: "get",
    params,
  });
}
export function matchList(params: any) {
  return request({
    url: "/c-page/match/list",
    method: "get",
    params,
  });
}
export function getMatch(params: any) {
  return request({
    url: "/c-page/match-banner",
    method: "get",
    params,
  });
}
